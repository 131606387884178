<template>
  <v-container class="px-lg-10 mx-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="px-8 py-8"
      >
        <v-badge
          bottom
          offset-x="25"
          offset-y="25"
          avatar
          color="white"
        >
          <v-avatar
            size="100"
          >
            <v-img
              :src="dataUser.p_avatar"
              alt="profileimage"
            />
          </v-avatar>
          <template
            v-slot:badge
          >
            <v-icon
              color="green"
              size="22"
              @click="changeAvatar"
            >
              mdi-pencil-circle
            </v-icon>
          </template>
        </v-badge>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="font-weight-bold text-h4 mb-n2 mt-4">
          Informasi Umum
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Divisi
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_pos_divisi }}
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Posisi
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_pos_name }}
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Email Travikr
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_email }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="font-weight-bold text-h4 mb-n2 mt-4">
          Data Pribadi
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_name }}
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Email Pribadi
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_email_personal }}
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nomor HP
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_phone }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="font-weight-bold text-h4 mb-n2 mt-4">
          Data Alamat
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Alamat
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_address_string }}
      </v-col>
    </v-row>

    <v-row class="mb-n2">
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Kode Pos
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        {{ dataUser.p_poscode }}
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-container>
          <v-row class="px-2">
            <v-col>
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                class="d-flex justify-center"
                style="width: 100%;"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                @vdropzone-file-added="vdropzoneFileAdded"
              >
                <div class="dropzone-custom-content">
                  <v-btn
                    class="mb-2"
                    color="purple"
                  >
                    UPLOAD FOTO
                  </v-btn>
                  <h4 class="dropzone-custom-title">
                    Atau tarik foto ke area ini untuk upload
                  </h4>
                </div>
              </vue-dropzone>
            </v-col>
          </v-row>

          <!-- <v-card-text /> -->
          <v-card-actions>
            <v-btn
              class="mb-2"
              color="purple"
              block
              @click="saveButtonClick"
            >
              SIMPAN FOTO
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      vueDropzone: vue2Dropzone,
    },

    data: () => ({
      dialog: false,
      tab: null,
      dataUser: [],
      profilePicture: {},
      profilePictureUploadedHash: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        autoQueue: false,
        autoProcessQueue: false,
        maxFiles: 1,
      },
    }),

    computed: {
      // passwordConfirmationRule () {
      //   return this.password.newPassword === this.password.confirmPassword || 'Password must match'
      // },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }

        const vm = this

        axios.post('/t/profile/personal').then(function (response) {
          if (response.data.status === 200) {
            vm.dataUser = response.data.data.profile
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      saveButtonClick () {
        const requestBody = {
          hash_avatar: JSON.stringify(this.profilePictureUploadedHash),
        }

        axios.post('t/profile/personal/changeAvatar', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize()
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })

        this.dialog = false
      },

      vdropzoneFileAdded (file) {
        const dropzoneFiles = this.$refs.myVueDropzone.dropzone.files
        if (dropzoneFiles.length > 1) {
          document.querySelector('.dz-preview').remove()
          dropzoneFiles.shift()
        }

        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', file)

        axios.post(baseCDNurl + '/t/profile/personal/uploadAvatar', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            document.querySelectorAll('.dz-details').forEach(el => el.remove())
            document.querySelectorAll('.dz-progress').forEach(el => el.remove())
            this.profilePictureUploadedHash.push(res.data.data.tmp_file_hash)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      changeAvatar () {
        this.profilePicture = {}
        this.profilePictureUploadedHash = []
        this.dialog = true
        this.$nextTick(() => {
          this.$refs.myVueDropzone.removeAllFiles()
        })
      },
    },
  }
</script>
